<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
      :lastPage="nLastPage"
      @pageChange="fetchResumoMapaGeral(filters, $event)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Mapa Resumo</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <FilterMapaResumo
            @selectedFilters="fetchResumoMapaGeral((filters = $event))"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.id_band`]="{ item }">
        <v-icon :color="bandeiras[item.id_band].color">
          {{ bandeiras[item.id_band].icon }}
        </v-icon>
        <!-- {{item.id_band + ' - helow'}} -->
      </template>
      <template v-slot:[`item.colaborador`]="{ item }">
        {{ item.id_colaborador }} - {{ item.colaborador | TitleCase }}
      </template>

      <template v-slot:[`item.funcao`]="{ item }">
        {{ item.funcao | TitleCase }}
      </template>

      <template v-slot:[`item.valor_com_cnh`]="{ item }">
        {{ item.valor_com_cnh | BrazilianCurrency }}
      </template>

      <template v-slot:[`item.valor_com_cdc`]="{ item }">
        {{ item.valor_com_cdc | BrazilianCurrency }}
      </template>

      <template v-slot:[`item.valor_com_ent`]="{ item }">
        {{ item.valor_com_ent | BrazilianCurrency }}
      </template>

      <template v-slot:[`item.valor_com_acessorio`]="{ item }">
        {{ item.valor_com_acessorio | BrazilianCurrency }}
      </template>

      <template v-slot:[`item.valor_dev_veiculo`]="{ item }">
        {{ item.valor_dev_veiculo | BrazilianCurrency }}
      </template>

      <template v-slot:[`item.valor_bonus_cnh`]="{ item }">
        {{ item.valor_bonus_cnh | BrazilianCurrency }}
      </template>

      <template v-slot:[`item.valor_com_outros`]="{ item }">
        {{ item.valor_com_outros | BrazilianCurrency }}
      </template>

      <template v-slot:[`item.valor_compl_dif`]="{ item }">
        {{ item.valor_compl_dif | BrazilianCurrency }}
      </template>

      <template v-slot:[`item.total`]="{ item }">
        {{ item.total | BrazilianCurrency }}
      </template>
      <template v-slot:footer>
        <hr />
        <v-row justify="end">
          <strong class="mr-8">
            Total: {{ valorInicial | BrazilianCurrency }}
          </strong>
        </v-row>
      </template>
      <template v-slot:[`item.dept`]="{ item }">
        {{ item.departamento | TitleCase }}
      </template>
    </BaseTable>
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable.vue";
import FilterMapaResumo from "./FilterMapaResumo";
import comissao from "@/services/http/comissaoService";
import currencyMixin from "@/mixins/currencyMixin";
import bandeiras from "@/mixins/bandeiras";

export default {
  components: {
    BaseTable,
    FilterMapaResumo
  },

  mixins: [currencyMixin, bandeiras],
  data() {
    return {
      loading: false,
      search: "",
      filters: {},
      nLastPage: 0,
      valores: 0,
      valorInicial: 0,
      resultTotal: [],
      valorFinal: [],
      headers: [
        { text: "Segmento", value: "id_band" },
        { text: "Colaborador", value: "colaborador" },
        { text: "Função", value: "funcao" },
        { text: "Situação", value: "situacao" },
        { text: "CNH", value: "valor_com_cnh", align: "right" },
        { text: "NV/US", value: "valor_com_cdc", align: "right" },
        { text: "ENTR.", value: "valor_com_ent", align: "right" },
        { text: "Acess.", value: "valor_com_acessorio", align: "right" },
        { text: "Dev.", value: "valor_dev_veiculo", align: "right" },
        { text: "B.CNH", value: "valor_bonus_cnh", align: "right" },
        { text: "Outros", value: "valor_com_outros", align: "right" },
        { text: "Manual", value: "valor_compl_dif", align: "right" },
        { text: "Total", value: "total", align: "right" },
        { text: "Departamento", value: "dept" }
      ],
      info: [],
      dados: []
    };
  },

  methods: {
    async fetchResumoMapaGeral(filters, nPage = 1) {
      this.loading = true;
      let { data } = await comissao()
        .resumoMapaGeral()
        .show({
          page: nPage,
          ...filters
        });
      this.nLastPage = data.last_page;
      this.info = data.data;
      this.loading = false;

      this.valorFinal = this.calculaTotal(data.data);
    },
    async calculaTotal() {
      for (let i = 0; i < this.info.length; i++) {
        this.resultTotal.push(parseFloat(this.info[i].total));
      }
      this.valorInicial = this.resultTotal.reduce(
        (prev, curr) => prev + curr,
        this.valores
      );
      this.excluiArray();
    },

    excluiArray() {
      this.resultTotal.splice(0, this.resultTotal.length);
    }
  },

  mounted() {
    this.fetchResumoMapaGeral();
  }
};
</script>

<style></style>
