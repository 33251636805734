const bandeirasMixin = {
  computed: {
    bandeiras() {
      return {
        2: {
          icon: "mdi-motorbike",
          color: "error"
        },
        4: {
          icon: "mdi-car",
          color: "primary"
        },
        5: {
          icon: "mdi-cow",
          color: "brown"
        },
        6: {
          icon: "tow-truck",
          color: "green"
        },
        7: {
          icon: "mdi-hexagon-multiple",
          color: "sucess"
        }
      };
    }
  }
};

export default bandeirasMixin;
