<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>
    <v-card>
      <v-container>
        <v-switch
          v-model="filters.ativo"
          true-value="S"
          false-value="N"
          :label="`${filters.ativo == 'S' ? 'Ativo' : 'Inativo'}`"
          @change="handleFilter()"
        ></v-switch>
        <BaseFilter
          prepend-inner-icon="mdi-file-table-box-multiple-outline"
          clearable
          single-line
          label="Segmento"
          item-text="descricao"
          item-value="id_band"
          service="sistemaService.tipoBandeiras"
          :filters="{ id_band: '2,4' }"
          v-model="filters.id_band"
          :isCompany="true"
          @change="handleFilter()"
        />

        <BaseFilter
          prepend-inner-icon="mdi-domain"
          clearable
          single-line
          label="Empresa"
          item-text="apelido"
          item-value="id_empresa"
          service="sistemaService.empresa"
          :filters="{ id_band: filters.id_band, calcula_comissao: 'S' }"
          v-model="filters.id_empresa"
          :isCompany="true"
          @change="handleFilter()"
        />

        <BaseFilter
          prepend-inner-icon="mdi-account"
          clearable
          single-line
          label="Colaborador"
          item-text="colaborador"
          item-value="id_colaborador"
          service="sistemaService.colaboradorEmpresa"
          :filters="{
            id_band: filters.id_band,
            id_empresa: filters.id_empresa,
            ativo: 'S'
          }"
          v-model="filters.id_colaborador"
          :hasCode="true"
          @change="handleFilter()"
        />

        <BaseFilter
          label="Função"
          service="sistemaService.funcoes"
          v-model="filters.id_funcao"
          item-text="descricao"
          item-value="id_funcao"
          prepend-inner-icon="mdi-account-tie"
          clearable
          chips
          single-line
          :filters="{ id_band: filters.id_band, ativo: 'S' }"
          :hasCode="true"
          :multiple="false"
          @change="handleFilter()"
        />

        <BaseFilter
          label="Departamento"
          v-model="filters.id_depto"
          service="comissaoService.departamentos"
          item-text="departamento"
          item-value="id_depto"
          single-line
          prepend-inner-icon="mdi-toolbox"
          clearable
          :multiple="false"
          @change="handleFilter()"
        />
        <DatePickerMonth @date="assignDateFilter($event)" />
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import BaseFilter from "@/components/shared/BaseSelectService";
import FilterBottom from "@/components/shared/bottons/FilterBottom";
import DatePickerMonth from "@/components/shared/DatePickerMonth";

export default {
  components: {
    BaseFilter,
    FilterBottom,
    DatePickerMonth
  },

  data() {
    return {
      filters: {
        ativo: "S"
      }
    };
  },

  methods: {
    handleFilter() {
      this.$emit("selectedFilters", this.filters);
    },
    assignDateFilter(date) {
      this.filters["ano_ref"] = date?.ano_ref;
      this.filters["mes_ref"] = date?.mes_ref;
      this.handleFilter();
    }
  }
};
</script>

<style></style>
