const currencyMixin = {
  filters: {
    BrazilianCurrency(value) {
      if (typeof value == "string") {
        value = value.trim().replaceAll(",", "");
      }

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(value);
    }
  }
};

export default currencyMixin;
