<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
      :lastPage="nLastPage"
      @pageChange="fetchComissao(filters, $event)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Comissão</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-spacer></v-spacer>
          <FilterComissao
            @selectedFilters="fetchComissao((filters = $event))"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.id_band`]="{ item }">
        <v-icon :color="bandeiras[item.id_band].color" dark>
          {{ bandeiras[item.id_band].icon }}
        </v-icon>
      </template>
      <template v-slot:[`item.colaborador`]="{ item }">
        {{ item.id_colaborador }} - {{ item.colaborador | TitleCase }}
      </template>
      <template v-slot:[`item.departamento`]="{ item }">
        {{ item.departamento | TitleCase }}
      </template>
      <template v-slot:[`item.funcao`]="{ item }">
        {{ item.funcao | TitleCase }}
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip
          :color="statusAtivo[item.ativo].color"
          dark
          @click="statusItem(item)"
        >
          {{ statusAtivo[item.ativo].text }}
        </v-chip>
      </template>
      <template v-slot:[`item.comissao`]="{ item }">
        <v-btn icon @click="commissionShow(item)" color="orange darken-1" dark>
          <v-icon>mdi-email</v-icon>
        </v-btn>
      </template>
    </BaseTable>
    <ModalComissao
      :dialog="dialog"
      :colaborador="colaborador"
      @close="dialog = false"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable.vue";
import sistema from "@/services/http/sistemaService";
import ModalComissao from "./ModalComissao";
import FilterComissao from "./FilterComissao.vue";
import statusAtivo from "@/mixins/statusAtivo";
import banderiras from "@/mixins/bandeiras";
export default {
  components: {
    BaseTable,
    ModalComissao,
    FilterComissao
  },

  mixins: [statusAtivo, banderiras],

  data() {
    return {
      loading: false,
      dialog: false,
      search: "",
      labelBtn: "Salvar",
      filters: "",
      nLastPage: 0,
      headers: [
        { text: "Bandeira", value: "id_band" },
        { text: "Empresa", value: "apelido" },
        { text: "Colaborador", value: "colaborador" },
        { text: "Departamento", value: "departamento" },
        { text: "Função", value: "funcao" },
        { text: "Status", value: "ativo" },
        { text: "Comissão", value: "comissao", align: "center" }
      ],
      info: [],
      colaborador: {}
    };
  },

  methods: {
    async fetchComissao(filter, nPage = 1) {
      this.loading = true;
      let { data } = await sistema()
        .colaboradorEmpresa()
        .show({
          page: nPage,
          ativo: "S",
          ...filter
        });
      this.nLastPage = data.last_page;
      this.loading = false;
      this.info = data.data;
    },

    commissionShow(item) {
      this.colaborador = {
        id_colaborador: item.id_colaborador,
        nome: item.colaborador
      };

      this.dialog = true;
    }
  },

  mounted() {
    this.fetchComissao();
  }
};
</script>
