var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.info,"loading":_vm.loading,"paginate":true,"lastPage":_vm.nLastPage},on:{"pageChange":function($event){return _vm.fetchResumoMapaGeral(_vm.filters, $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Mapa Resumo")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('FilterMapaResumo',{on:{"selectedFilters":function($event){_vm.fetchResumoMapaGeral((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.bandeiras[item.id_band].color}},[_vm._v(" "+_vm._s(_vm.bandeiras[item.id_band].icon)+" ")])]}},{key:"item.colaborador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_colaborador)+" - "+_vm._s(_vm._f("TitleCase")(item.colaborador))+" ")]}},{key:"item.funcao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.funcao))+" ")]}},{key:"item.valor_com_cnh",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_com_cnh))+" ")]}},{key:"item.valor_com_cdc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_com_cdc))+" ")]}},{key:"item.valor_com_ent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_com_ent))+" ")]}},{key:"item.valor_com_acessorio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_com_acessorio))+" ")]}},{key:"item.valor_dev_veiculo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_dev_veiculo))+" ")]}},{key:"item.valor_bonus_cnh",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_bonus_cnh))+" ")]}},{key:"item.valor_com_outros",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_com_outros))+" ")]}},{key:"item.valor_compl_dif",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_compl_dif))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.total))+" ")]}},{key:"footer",fn:function(){return [_c('hr'),_c('v-row',{attrs:{"justify":"end"}},[_c('strong',{staticClass:"mr-8"},[_vm._v(" Total: "+_vm._s(_vm._f("BrazilianCurrency")(_vm.valorInicial))+" ")])])]},proxy:true},{key:"item.dept",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.departamento))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }