<template>
  <v-container class="container-card">
    <v-card>
      <v-tabs v-model="tab" class="pa-2">
        <v-tab>
          Comissão
        </v-tab>
        <v-tab>
          Mapa Resumo
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Comissao />
        </v-tab-item>
        <v-tab-item>
          <MapaResumo />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import Comissao from "@/components/comissao/comissoes/Comissao";
import MapaResumo from "@/components/comissao/comissoes/mapaResumo/MapaResumo";
import { mapActions } from "vuex";
export default {
  components: {
    Comissao,
    MapaResumo
  },

  data() {
    return {
      tab: null
    };
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Consulta Comissão");
  }
};
</script>

<style scoped>
.v-tab--active {
  background-color: white;
}
</style>
