<template>
  <v-dialog v-model="dialog" persistent max-width="70%">
    <Loader :active="loading" message="Gerando relatório..." />
    <v-card>
      <!-- <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            Sua Comissão
          </span>
        </v-toolbar>
      </v-card-title> -->
      <v-card-text>
        <v-row>
          <v-col class="pb-0 d-flex align-center" cols="12" md="7" lg="7">
            <span style="font-size: 1.1rem; font-weight: 600;">
              {{ colaborador.nome }}
            </span>
          </v-col>
          <v-col class="pb-0" cols="12" md="3" lg="3">
            <DatePickerMonth
              :resetDate="resetDate"
              @date="assignDateFilter($event)"
            />
          </v-col>
          <v-col class="pb-0 d-flex align-center" cols="12" md="2" lg="2">
            <v-btn color="primary" @click="fetchComissao()">
              <v-icon>mdi-magnify</v-icon>
              Buscar
            </v-btn>
          </v-col>
        </v-row>
        <v-simple-table>
          <template v-slot:default>
            <thead style="background-color: #d6d6d6;">
              <tr>
                <th class="text-left">
                  Indicador
                </th>
                <th class="text-left">
                  Classe
                </th>
                <th class="text-left" style="text-align: right !important;">
                  Meta
                </th>
                <th class="text-left" style="text-align: right !important;">
                  Realizado
                </th>
                <th class="text-left" style="text-align: right !important;">
                  B. Cálculo
                </th>
                <th class="text-left" style="text-align: right !important;">
                  Comissão
                </th>
                <th class="text-left" style="text-align: right !important;">
                  Perc. %
                </th>
                <th class="text-left" style="text-align: right !important;">
                  V. Perdido
                </th>
              </tr>
            </thead>
            <tbody v-if="info.length != 0">
              <tr v-for="item in info" :key="item.id_comissao">
                <td>{{ item.id_indicador }} - {{ item.indicador }}</td>
                <td>{{ item.tipo_classe }}</td>
                <td style="text-align: right;">{{ item.valor_meta }}</td>
                <td style="text-align: right;">{{ item.valor_realizado }}</td>
                <td style="text-align: right;">
                  {{ item.valor_base | BrazilianCurrency }}
                </td>
                <td style="text-align: right;">
                  {{ item.valor_com | BrazilianCurrency }}
                </td>
                <td style="text-align: right;">{{ item.perc }}%</td>
                <td style="text-align: right;">
                  {{ item.valor_perdido | BrazilianCurrency }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <td colspan="8" style="text-align: center; color: #c0c0c0">
                Não há dados disponíveis
              </td>
            </tbody>
            <tfoot style="background-color: #d6d6d6;">
              <tr>
                <th colspan="2" style="text-align: left; font-size: 1.1rem">
                  Total
                </th>
                <th style="text-align: right">
                  <div class="d-flex flex-column">
                    <span style="color: rgba(0, 0, 0, 0.6)">V. Salário</span>
                    <span style="font-size: 1.1rem">{{
                      footerTotal.salario | BrazilianCurrency
                    }}</span>
                  </div>
                </th>
                <th style="text-align: right">
                  <div class="d-flex flex-column">
                    <span style="color: rgba(0, 0, 0, 0.6)">V. Bônus</span>
                    <span style="font-size: 1.1rem">{{
                      footerTotal.bonus | BrazilianCurrency
                    }}</span>
                  </div>
                </th>
                <th style="text-align: right">
                  <div class="d-flex flex-column">
                    <span style="color: rgba(0, 0, 0, 0.6)">V. Comissão</span>
                    <span style="font-size: 1.1rem">{{
                      footerTotal.comissao | BrazilianCurrency
                    }}</span>
                  </div>
                </th>
                <th style="text-align: right">
                  <div class="d-flex flex-column">
                    <span style="color: rgba(0, 0, 0, 0.6)">V. a Receber</span>
                    <span style="font-size: 1.1rem">{{
                      footerTotal.aReceber | BrazilianCurrency
                    }}</span>
                  </div>
                </th>
                <th style="text-align: right">
                  <div class="d-flex flex-column">
                    <span style="color: rgba(0, 0, 0, 0.6)">V. Possível</span>
                    <span style="font-size: 1.1rem">{{
                      footerTotal.possivel | BrazilianCurrency
                    }}</span>
                  </div>
                </th>
                <th style="text-align: right">
                  <div class="d-flex flex-column">
                    <span style="color: rgba(0, 0, 0, 0.6)">V. Perdido</span>
                    <span style="font-size: 1.1rem">{{
                      footerTotal.perdido | BrazilianCurrency
                    }}</span>
                  </div>
                </th>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="this.info != 0"
          color="primary"
          text
          @click="fetchReport(footerTotal.item)"
        >
          Gerar PDF
        </v-btn>
        <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import jasperService from "@/services/http/jasperService";
import Loader from "@/components/shared/LoaderCometa";
import comissao from "@/services/http/comissaoService";
import DatePickerMonth from "@/components/shared/DatePickerMonth";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  name: "ModalComissao",

  mixins: [currencyMixin],

  components: {
    DatePickerMonth,
    Loader
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    colaborador: {
      type: Object
    },

    route: {
      type: String
    },
    list: {
      type: Array
    }
  },

  data() {
    return {
      info: [],
      filters: {},
      loading: false,
      selectedItem: "",
      resetDate: false,
      footerTotal: {
        salario: 0,
        bonus: 0,
        comissao: 0,
        aReceber: 0,
        perdido: 0,
        possivel: 0
      }
    };
  },

  watch: {},

  methods: {
    async fetchComissao() {
      // (this.footerTotal.salario = 0),
      //   (this.footerTotal.bonus = 0),
      //   (this.footerTotal.comissao = 0),
      //   (this.footerTotal.aReceber = 0),
      //   (this.footerTotal.perdido = 0),
      //   (this.footerTotal.possivel = 0);
      let { data } = await comissao().show({
        per_page: -1,
        id_colaborador: this.colaborador.id_colaborador,
        ...this.filters
      });
      this.info = data.data;
      if (this.info.length == 0) {
        return this.$toast.error("Não há dados disponíveis");
      }
      this.info.map(item => {
        if (item.valor_com != null && item.id_tipo_classe == "S") {
          this.footerTotal.salario = parseFloat(item.valor_base);
        }

        if (item.valor_com != null && item.id_tipo_classe == "B") {
          this.footerTotal.bonus += parseFloat(item.valor_com);
        }

        if (item.valor_com != null && item.id_tipo_classe == "C") {
          this.footerTotal.comissao += parseFloat(item.valor_com);
        }

        if (item.valor_perdido != null) {
          this.footerTotal.perdido += parseFloat(item.valor_perdido);
        }

        // if (item.valor_com != null) {
        //   this.totalComissao += parseFloat(item.valor_com);
        // } else {
        //   this.totalComissao += 0;
        // }
      });

      this.footerTotal.aReceber =
        this.footerTotal.salario +
        this.footerTotal.bonus +
        this.footerTotal.comissao;
      this.footerTotal.possivel =
        this.footerTotal.bonus + this.footerTotal.comissao;
    },

    assignDateFilter(date) {
      this.filters["ano_ref"] = date?.ano_ref;
      this.filters["mes_ref"] = date?.mes_ref;
    },
    generatePdf(data) {
      var blob = new Blob([data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    },

    async fetchReport() {
      this.loading = true;
      let { data } = await jasperService()
        .comissao()
        .comissaoGeralUnico()
        .show({
          in_id_colaborador: this.colaborador.id_colaborador,
          in_mes_ref: this.filters.mes_ref,
          in_ano_ref: this.filters.ano_ref
        });
      this.loading = false;
      this.generatePdf(data);
    },

    close() {
      (this.footerTotal.salario = 0),
        (this.footerTotal.bonus = 0),
        (this.footerTotal.comissao = 0),
        (this.footerTotal.aReceber = 0),
        (this.footerTotal.perdido = 0),
        (this.footerTotal.possivel = 0);
      this.resetDate = true;
      this.info = [];
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.adjust >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.textStyle {
  color: #000;
  font-weight: 400 !important;
}
</style>
