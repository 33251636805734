var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.info,"loading":_vm.loading,"paginate":true,"lastPage":_vm.nLastPage},on:{"pageChange":function($event){return _vm.fetchComissao(_vm.filters, $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Comissão")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('FilterComissao',{on:{"selectedFilters":function($event){_vm.fetchComissao((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.bandeiras[item.id_band].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.bandeiras[item.id_band].icon)+" ")])]}},{key:"item.colaborador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_colaborador)+" - "+_vm._s(_vm._f("TitleCase")(item.colaborador))+" ")]}},{key:"item.departamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.departamento))+" ")]}},{key:"item.funcao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.funcao))+" ")]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusAtivo[item.ativo].color,"dark":""},on:{"click":function($event){return _vm.statusItem(item)}}},[_vm._v(" "+_vm._s(_vm.statusAtivo[item.ativo].text)+" ")])]}},{key:"item.comissao",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"orange darken-1","dark":""},on:{"click":function($event){return _vm.commissionShow(item)}}},[_c('v-icon',[_vm._v("mdi-email")])],1)]}}],null,true)}),_c('ModalComissao',{attrs:{"dialog":_vm.dialog,"colaborador":_vm.colaborador},on:{"close":function($event){_vm.dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }