<template>
  <div v-if="active" style="position: absolute">
    <v-container class="fill-height" fluid>
      <v-overlay color="grey darken-2" :value="true">
        <img :src="logo" alt="Logo" width="400" />
        <span
          style="font-size: 2em; font-weight: bold; color: #212121"
          class="d-flex justify-center"
          >{{ message }}</span
        >
      </v-overlay>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "LoaderCometa",

  props: {
    message: {
      type: String
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      logo: ""
    };
  },

  created() {
    this.logo = require(`../../assets/logo-load.svg`);
  }
};
</script>
